/* Hide scrollbar for Chrome, Safari and Opera */
html {
  width: 100vw;
  overflow-x: hidden;
  background-color: transparent !important;
}

body {
  background: radial-gradient(57.8% 36.6% at 50% 36.6%, rgba(221, 250, 240, 0.765) 0%, rgba(221, 250, 240, 0) 100%), radial-gradient(39.56% 39.09% at 60.44% 55.86%, rgba(255, 247, 217, 0.9) 0%, rgba(255, 247, 217, 0) 100%), radial-gradient(36.56% 40.55% at 46.16% 52.85%, rgba(245, 217, 245, 0.9) 0%, rgba(245, 217, 245, 0) 100%), linear-gradient(rgb(250, 250, 250) 0%, rgb(254, 250, 253) 100%);

}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.editorClassName {
  padding-left: 5px;
  padding-right: 5px;
  min-height: 100px;
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: grayscale(100%) !important;
}

lottie-player {
  margin: 0 auto;
}

.markdown>p>img {
  max-width: 100%;
  margin: auto !important;
}

/* .cirip-shadow {
  background-color: #F9F7F5;
} */

/* .cirip-shadow:after {
  content: '';
    display: block;
    position: absolute;
    top: 63px;
    width: 94%;
    left: 3%;
    border-top: 1px solid #f1ede8; */
/* content: '';
  display: block;
  position: absolute;
  width: 60%;
  height: 3px;
  top: 63px;
  left: 20%;
  background: -webkit-radial-gradient(center, ellipse cover, rgba(80, 80, 80, 0.25) 0%,rgba(114, 114, 114, 0) 85%);  */
/* } */

.place {
  min-height: 400px;
  min-width: 480px;
}

.container {
  max-width: 348px;
  margin-right: 10% !important;
}


onboard-v2>.network-container>a {
  display: none !important;
}

.logo {
  /* font-family: 'Righteous'; */
}

/* .hide-mobile {
  display: ;
} */

@media only screen and (max-width: 850px) {
  .place {
    min-height: 300px;
    min-width: 350px;
  }
}

@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: flex;
  }
}

/* [hidden] {
  display: none !important;
} */

:root {

  /* CUSTOMIZE ACCOUNT CENTER*/
  /* --account-center-position-top
  --account-center-position-bottom */
  /* --account-center-position-right: 50% */
  /* --account-center-position-left */
  /* --account-center-minimized-background
  --account-center-maximized-upper-background
  --account-center-maximized-network-section
  --account-center-maximized-app-info-section
  --account-center-minimized-address-color
  --account-center-maximized-address-color
  --account-center-maximized-account-section-background-hover
  --account-center-maximized-action-background-hover
  --account-center-minimized-chain-select-background
  --account-center-network-selector-color
  --account-center-maximized-network-selector-color
  --account-center-minimized-network-selector-color
  --account-center-app-btn-text-color
  --account-center-app-btn-background
  --account-center-app-btn-font-family

  --account-center-border
  --account-center-box-shadow
  --account-center-border-radius
  --account-center-chain-warning
  --account-center-minimized-balance-color
  --account-center-minimized-chain-select-background

  --account-center-maximized-network-section-background
  --account-center-maximized-network-text-color
  --account-center-maximized-info-section-background-color
  --account-center-maximized-upper-action-color
  --account-center-maximized-upper-action-background-hover
  --account-center-maximized-app-name-color
  --account-center-maximized-app-info-color

  --account-center-micro-background */
}